<template>
  <div class="page4 flex_center">
    <div class="content">
      <div class="bg img_pub" :style="{ backgroundImage: `url(${bg})` }"></div>
      <div
        class="page4_arrow img_pub"
        :style="{ backgroundImage: `url(${page4_arrow})` }"
      ></div>
      <van-swipe class="swipe" :loop="false" :show-indicators="false">
        <van-swipe-item>
          <img class="boy_seat" :src="boy_seat" width="80%" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="girl_seat" :src="girl_seat" width="80%" />
        </van-swipe-item>
      </van-swipe>
      <u-animate
        name="animate__fadeIn"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false"
      >
        <div class="title">
          <div class="title_top">
            <div>COUPLE NOTEBOOK</div>
            <div>一捧日月-恋之记</div>
          </div>
          <div class="title_content">
            <p>
              融合了现实和虚拟的情侣笔记本，共同记录着爱情的美好瞬间——通过恒记APP扫描，出现你与TA的记录
            </p>
            <p>可以记录自己悄悄话，也可以同步俩人的在一起的甜蜜时</p>
            <p>时刻记录，同步内容</p>
          </div>
        </div>
      </u-animate>
    </div>
  </div>
</template>

<script>
import bg from "@/assets/img/mobile/bg.png";
import boy_seat from "@/assets/img/mobile/boy_seat.png";
import girl_seat from "@/assets/img/mobile/girl_seat.png";
import page4_arrow from "@/assets/img/mobile/page4_arrow.png";
export default {
  data() {
    return {
      bg,
      boy_seat,
      girl_seat,
      page4_arrow,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page4 {
  height: 100vh;
  background: #000;
  position: relative;
  text-align: center;
  .img_pub {
    top: 0;
    left: 0;
    right: 0;
    bottom: 160px;
    position: absolute;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: 100%;
  }
  .bg {
    z-index: 0;
  }
  .page4_arrow {
    z-index: 1;
    margin: 0 20px;
  }
  .swipe {
    width: 100vw;
    position: relative;
    z-index: 1;
    padding-top: 120px;
  }
  .title {
    position: relative;
    z-index: 1;
    color: #b8996c;
    transform: translateY(calc(100% - 320px)) !important;
    .title_top {
      div:first-child {
        font-size: 0.5rem;
      }
      div:last-child {
        font-size: 2rem;
        font-family: Taipei Sans TC Beta-Bold, Taipei Sans TC Beta;
        font-weight: bold;
      }
    }
    .title_content {
      width: calc(100% - 190px);
      margin: 0 auto;
      font-size: 12px;
      line-height: 28px;
      letter-spacing: 2px;
      p {
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 359px) {
  .title_content {
    width: calc(100% - 80px) !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 374px) {
  .title {
    transform: translateY(calc(100% - 320px)) !important;
  }
  .title_content {
    width: calc(100% - 150px) !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 385px) {
  .title {
    transform: translateY(calc(100% - 320px)) !important;
  }
  .title_content {
    width: calc(100% - 120px) !important;
  }
}
@media screen and (min-width: 386px) and (max-width: 392px) {
}
@media screen and (min-width: 393px) and (max-width: 400px) {
}
@media screen and (min-width: 401px) and (max-width: 414px) {
  .title_content {
    width: calc(100% - 220px) !important;
  }
}
@media screen and (min-width: 750px) and (max-width: 799px) {
}
</style>
