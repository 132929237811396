<template>
  <div class="page5 flex_center">
    <img class="page5_title" :src="page5_title" ondragstart="return false;" />
    <transition name="fade-up-50">
      <img
        class="page5_box"
        :src="page5_box"
        v-if="$parent.index === 5"
        ondragstart="return false;"
      />
    </transition>
    <div class="foot_left color_f">
      <img class="page5_btn" :src="page5_btn" ondragstart="return false;" />
      <!-- 模型 -->
      <div id="unity-container">
        <canvas
          id="unity-canvas"
          width="100"
          height="150"
          style="background: transparent; opacity: 0"
        ></canvas>

        <div id="unity-loading-bar">
          <div id="unity-logo"></div>
          <div id="unity-progress-bar-empty">
            <div id="unity-progress-bar-full"></div>
          </div>
        </div>
        <div id="unity-warning"></div>
        <div id="unity-footer">
          <div id="unity-webgl-logo" style="opacity: 0"></div>
          <div id="unity-fullscreen-button" style="opacity: 0"></div>
          <div id="unity-build-title"></div>
        </div>
      </div>
      <div class="unity_title">随书附赠星贴贴</div>
    </div>
    <img
      class="head next_btn"
      :src="next_btn"
      ondragstart="return false;"
      @click="$parent.navTo(6)"
    />
  </div>
</template>

<script>
import next_btn from "@/assets/img/pc/next_btn.png";
import page5_title from "@/assets/img/pc/page5_title.png";
import page5_box from "@/assets/img/pc/page5_box.png";
import page5_btn from "@/assets/img/pc/page5_btn.png";
export default {
  data() {
    return {
      next_btn,
      page5_title,
      page5_box,
      page5_btn,
      container: Object,
      canvas: Object,
      loadingBar: Object,
      progressBarFull: Object,
      fullscreenButton: Object,
      warningBanner: Object,
    };
  },
  mounted() {
    this.unityShowBanner();
  },
  methods: {
    unityShowBanner(msg, type) {
      this.container = document.querySelector("#unity-container");
      this.canvas = document.querySelector("#unity-canvas");
      this.loadingBar = document.querySelector("#unity-loading-bar");
      this.progressBarFull = document.querySelector("#unity-progress-bar-full");
      this.fullscreenButton = document.querySelector(
        "#unity-fullscreen-button"
      );
      this.warningBanner = document.querySelector("#unity-warning");
      this.updateBannerVisibility();
      var div = document.createElement("div");
      div.innerHTML = msg;
      this.warningBanner.appendChild(div);
      if (type == "error") div.style = "background: red; padding: 10px;";
      else {
        if (type == "warning") div.style = "background: yellow; padding: 10px;";
        setTimeout(function () {
          if (this.warningBanner) {
            this.warningBanner.removeChild(div);
            this.updateBannerVisibility();
          }
        }, 5000);
      }
      this.updateBannerVisibility();
      var buildUrl = "Build";
      var loaderUrl = buildUrl + "/Web.loader.js";
      var config = {
        dataUrl: buildUrl + "/Web.data.unityweb",
        frameworkUrl: buildUrl + "/Web.framework.js.unityweb",
        codeUrl: buildUrl + "/Web.wasm.unityweb",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "DefaultCompany",
        productName: "LotteryDemo",
        productVersion: "0.1.0",
        showBanner: this.unityShowBanner,
      };
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // Mobile device style: fill the whole browser client area with the game canvas:

        var meta = document.createElement("meta");
        meta.name = "viewport";
        meta.content =
          "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
        document.getElementsByTagName("head")[0].appendChild(meta);
        container.className = "unity-mobile";
        canvas.className = "unity-mobile";

        // To lower canvas resolution on mobile devices to gain some
        // performance, uncomment the following line:
        // config.devicePixelRatio = 1;

        this.unityShowBanner(
          "WebGL builds are not supported on mobile devices."
        );
      } else {
        // Desktop style: Render the game canvas in a window that can be maximized to fullscreen:

        this.canvas.style.width = "400";
        this.canvas.style.height = "600px";
      }

      this.loadingBar.style.display = "block";

      var script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        createUnityInstance(this.canvas, config, (progress) => {
          this.progressBarFull.style.width = 100 * progress + "%";
        })
          .then((unityInstance) => {
            //unityInstance.SetFullscreen(1);
            this.loadingBar.style.display = "none";
            setTimeout(function () {
              document.getElementById("unity-canvas").style.opacity = 1;
            }, 3000);
            this.fullscreenButton.onclick = () => {
              unityInstance.SetFullscreen(1);
            };
          })
          .catch((message) => {
            alert(message);
          });
      };
      document.body.appendChild(script);
    },
    updateBannerVisibility() {
      this.warningBanner.style.display = this.warningBanner.children.length
        ? "block"
        : "none";
    },
  },
};
</script>

<style lang="scss" scoped>
.page5 {
  width: 100%;
  height: 100%;
  background: #000000;
  img {
    position: absolute;
  }
  .page5_title {
    width: 100%;
  }
  .foot_left {
    position: absolute;
    bottom: 90px;
    left: 150px;
    text-align: center;
    .unity_title {
      transform: translateY(-20px);
    }
    .page5_btn {
      height: 24px;
      left: -20px;
      bottom: 230px;
    }
  }
}
#unity-warning {
  display: none !important;
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  #unity-canvas {
    height: 380px !important;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  #unity-canvas {
    height: 300px !important;
  }
  .page5_box {
    width: 700px;
  }
  .page5_btn {
    bottom: 190px !important;
  }
  .foot_left {
    left: 115px !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  #unity-canvas {
    height: 300px !important;
  }
  .page5_box {
    width: 600px;
  }

  .page5_btn {
    bottom: 190px !important;
  }
  .foot_left {
    left: 60px !important;
    bottom: 120px !important;
  }
}
</style>
