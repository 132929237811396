<template>
  <div class="foot flex align_center">
    <img class="foot_logo" :src="foot_logo" ondragstart="return false;" />
    <div class="foot_title color_f">
      <div class="mb20">© 2020-2023 星徒（上海）科技发展有限公司</div>
      <div class="flex mb20">
        <div>
          <a href="https://beian.miit.gov.cn/">沪ICP备2021025443号-1</a>
        </div>
        <div class="flex_center ml20">
          <img src="@/assets/img/pc/beian-icon.png" />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007132"
            >沪公网安备 31010102007132号</a
          ><br />
        </div>
      </div>
      <div>
        商务合作: <a href="mailto:bd@starman.ltd">bd@starman.ltd</a> | 招聘相关:
        <a href="mailto:hr@starman.ltd">hr@starman.ltd</a><br />
      </div>
    </div>
  </div>
</template>

<script>
import foot_logo from "@/assets/img/pc/foot_logo.png";
export default {
  data() {
    return {
      foot_logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.foot {
  padding: 100px 120px;
  justify-content: space-between;
  background: #000000;
  .foot_title {
    a {
      text-decoration: none;
      color: #ffffff;
    }
  }
}
.mb20{
  margin-bottom: 20px;
}
.ml20{
  margin-left: 20px;
}
</style>
