<template>
  <div class="page2 flex_center">
    <div class="content">
      <div class="bg" :style="{ backgroundImage: `url(${bg})` }"></div>
      <u-animate
        name="animate__fadeInDown"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false"
      >
        <img class="page2_img" :src="page2_img" width="100%" />
      </u-animate>
      <u-animate
        name="animate__fadeInUp"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false"
      >
        <img class="page2_title" :src="page2_title" />
      </u-animate>
    </div>
    <img
      class="mobile_btn"
      :src="next_btn"
      @click="$parent.$parent.$refs.navbar.mobileNavTo(3)"
    />
  </div>
</template>

<script>
import bg from "@/assets/img/mobile/bg.png";
import page2_img from "@/assets/img/mobile/page2_img.png";
import page2_title from "@/assets/img/mobile/page2_title.png";
import next_btn from "@/assets/img/mobile/next_btn.png";
export default {
  data() {
    return {
      bg,
      page2_img,
      page2_title,
      next_btn,
    };
  },
};
</script>

<style lang="scss" scoped>
.page2 {
  height: 100vh;
  background: #000;
  position: relative;
  text-align: center;
  .bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 160px;
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: 100%;
  }
  .page2_img {
    position: relative;
    z-index: 1;
  }
  .page2_title {
    width: calc(100vw - 180px);
    position: relative;
    z-index: 1;
  }
  .mobile_btn {
    position: absolute;
    z-index: 1;
    left: 50px;
  }
}
</style>
