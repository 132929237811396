<template>
  <div>
    <navBar @tabEmit="tabEmit" />
    <div id="wrap" :style="{ height: '100vh' }" v-show="loversNotes">
      <div id="main" :style="{ top: nowTop + 'px' }">
        <div id="page1" class="page">
          <page1 ref="1" />
        </div>
        <div id="page2" class="page">
          <page2 ref="2" />
        </div>
        <div id="page3" class="page">
          <page3 ref="3" />
        </div>
        <div id="page4" class="page">
          <page4 ref="4" />
        </div>
        <div id="page5" class="page">
          <page5 ref="5" />
        </div>
        <div id="page6" class="page">
          <page6 ref="6" />
        </div>
      </div>
    </div>
    <div class="calendar flex_center" v-show="!loversNotes">
      <img :src="expect" />
    </div>
    <foot />
  </div>
</template>

<script>
import page1 from "@/components/pc/page1.vue";
import page2 from "@/components/pc/page2.vue";
import page3 from "@/components/pc/page3.vue";
import page4 from "@/components/pc/page4.vue";
import page5 from "@/components/pc/page5.vue";
import page6 from "@/components/pc/page6.vue";
import navBar from "@/components/public/navBar.vue";
import foot from "@/components/public/foot.vue";
import expect from "@/assets/img/pc/expect.png";
export default {
  name: "pcHome",
  inject: ["reload"],
  components: {
    navBar,
    foot,
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
  },
  data() {
    return {
      expect,
      loversNotes: true,
      isPrevScroll: true, //是否往上滑
      isNextScroll: true, //是否往下滑 (swiper横向滚动不在第一个页面改为true)
      screenWeight: 0,
      screenHeight: 0,
      index: 0,
      curIndex: 0,
      startTime: 0,
      endTime: 0,
      nowTop: 0,
      pageNum: 0,
      main: Object,
      obj: Object,
      swiperArr: [4], // 判断在哪个组件中存在swiper横向鼠标滚动效果
    };
  },
  beforeMount() {
    window.scroll(0, 0);
  },
  mounted() {
    this.index = this.curIndex = 1;
    this.initScroll();
    window.addEventListener("resize", this.resizeWindow);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeWindow);
    document.body.style.overflowY = "auto";
    // 浏览器兼容
    if (navigator.userAgent.toLowerCase().indexOf("firefox") != -1) {
      document.removeEventListener("DOMMouseScroll", this.scrollFun, {
        passive: false,
      });
    } else if (document.removeEventListener) {
      document.removeEventListener("mousewheel", this.scrollFun, {
        passive: false,
      });
    } else if (document.attachEvent) {
      document.detachEvent("onmousewheel", this.scrollFun);
    } else {
      document.onmousewheel = null;
    }
  },
  watch: {
    index(val) {
      if (val == 3) {
        this.isPrevScroll = false;
        this.isNextScroll = false;
        this.$refs[val].clearNum();
        this.$refs[val].createContent();
      } else {
        this.$refs[3].removeScroll();
      }
    },
    loversNotes(val) {
      if (!val) {
        document.removeEventListener("mousewheel", this.scrollFun);
        document.removeEventListener("onmousewheel", this.scrollFun);
        document.body.style.overflowY = "auto";
      }
    },
  },
  methods: {
    resizeWindow() {
      this.screenWeight = document.documentElement.clientWidth;
      this.screenHeight = document.documentElement.clientHeight;
      this.nowTop = -(this.index - 1) * this.screenHeight;
      this.main = document.getElementById("main");
      this.obj = document.getElementsByTagName("div");
      for (let i = 0; i < this.obj.length; i++) {
        if (this.obj[i].className == "page") {
          this.obj[i].style.height = this.screenHeight + "px";
        }
      }
    },
    initScroll() {
      this.screenWeight = document.documentElement.clientWidth;
      this.screenHeight = document.documentElement.clientHeight;
      this.main = document.getElementById("main");
      this.obj = document.getElementsByTagName("div");
      for (let i = 0; i < this.obj.length; i++) {
        if (this.obj[i].className == "page") {
          this.obj[i].style.height = this.screenHeight + "px";
        }
      }
      this.pageNum = document.querySelectorAll(".page").length;
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
      }, 100);
      document.body.style.overflowY = "hidden";
      // 浏览器兼容
      if (navigator.userAgent.toLowerCase().indexOf("firefox") != -1) {
        document.addEventListener("DOMMouseScroll", this.scrollFun, {
          passive: false,
        });
      } else if (document.addEventListener) {
        document.addEventListener("mousewheel", this.scrollFun, {
          passive: false,
        });
      } else if (document.attachEvent) {
        document.attachEvent("onmousewheel", this.scrollFun);
      } else {
        document.onmousewheel = this.scrollFun;
      }
    },
    navTo(a) {
      this.index = a;
      if (this.swiperArr.includes(this.index)) {
        if (this.$refs[this.index].swiper != null) {
          this.$refs[this.index].swiper.slideTo(0, 500, false);
        }
        this.isNextScroll = false;
        this.isPrevScroll = true;
      } else {
        this.isPrevScroll = true;
        this.isNextScroll = true;
      }
      this.toPage(a);
    },
    scrollFun(event) {
      this.startTime = new Date().getTime();
      let delta = event.detail || -event.wheelDelta;
      if (this.startTime - this.endTime > 500) {
        if (
          delta > 0 &&
          parseInt(this.main.offsetTop) >=
            -(this.screenHeight * (this.pageNum - 2)) &&
          this.index < this.pageNum
        ) {
          if (this.isNextScroll) {
            // 向下滚动
            this.index++;
            this.toPage(this.index);
            if (this.swiperArr.includes(this.index)) {
              if (this.$refs[this.index].swiper != null) {
                this.$refs[this.index].swiper.slideTo(0, 0, false);
              }
              this.isNextScroll = false;
              this.isPrevScroll = true;
            } else {
              this.isPrevScroll = true;
              this.isNextScroll = true;
            }
          }
        } else if (
          delta < 0 &&
          parseInt(this.main.offsetTop) < 0 &&
          document.documentElement.scrollTop === 0 &&
          this.index > 1
        ) {
          if (this.isPrevScroll) {
            // 向上滚动
            this.index--;
            this.toPage(this.index);

            if (this.swiperArr.includes(this.index)) {
              if (this.$refs[this.index].swiper != null) {
                this.$refs[this.index].swiper.slideTo(
                  this.$refs[this.index].swiper.slides.length - 1,
                  0,
                  false
                );
              }
              this.isPrevScroll = false;
              this.isNextScroll = true;
            } else {
              this.isPrevScroll = true;
              this.isNextScroll = true;
            }
          }
        }
        this.endTime = new Date().getTime();
        if (this.index === this.pageNum) {
          document.body.style.overflowY = "auto";
        } else {
          document.body.style.overflowY = "hidden";
          document.documentElement.scrollTop = 0;
        }
      }
    },
    toPage(index) {
      if (index != this.curIndex) {
        const delta = index - this.curIndex;
        this.nowTop = this.nowTop - delta * this.screenHeight;
        this.curIndex = index;
        if (this.index === this.pageNum) {
          document.body.style.overflowY = "auto";
        } else {
          document.body.style.overflowY = "hidden";
          window.scrollTo(0, 0);
        }
      }
    },
    tabEmit(index) {
      if (index == 1) {
        this.loversNotes = false;
      } else {
        this.loversNotes = true;
        this.reload();
      }
    },
  },
};
</script>
<style scoped lang="scss">
#wrap {
  overflow: hidden;
  width: 100%;
}

.homeLine {
  width: 0.01rem;
  height: 4.86rem;
  background: linear-gradient(
    244deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 0 10px;
}

#main {
  position: relative;
  transition: top 0.5s;
}

.page {
  /*谨删*/
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.calendar {
  width: 100vw;
  height: 100vh;
  background: #ad2020;
}
</style>
