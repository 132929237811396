<template>
  <div class="nav_bar">
    <!-- 收起状态 -->
    <div class="nav_hide flex" v-show="!showNavBar">
      <img :src="logo_hide" />
      <div class="nav_tab flex">
        <div
          v-for="(item, index) in navTab"
          :key="item.code"
          @click="tabClick(index)"
        >
          <div class="nav_name head">{{ item.name }}</div>
          <img
            class="nav_underline"
            v-show="selectIndex == index"
            :src="underline"
            :style="{ width: selectIndex == 0 ? '48px' : '70px' }"
          />
        </div>
      </div>
      <img class="head" :src="button" @click="showNavBar = true" />
    </div>
    <!-- 展开状态 -->
    <el-drawer
      :visible.sync="showNavBar"
      direction="ttb"
      :size="''"
      :with-header="false"
    >
      <div class="nav_show flex">
        <img :src="logo_show" height="50px" />
        <div>
          <div
            class="head right_for"
            v-for="(item, index) in navTab"
            :key="item.code"
            @click="tabClick(index)"
          >
            <span class="show_name">{{ item.name }}</span>
            <img class="nav_arrow" :src="arrow" />
          </div>
        </div>
        <div class="nav_right head">
          <img :src="close" @click="showNavBar = !showNavBar" />
          <div class="right_tabs">
            <div
              v-for="(item, index) in rightTab"
              :key="item.code"
              @click="clickTab(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import logo_hide from "@/assets/img/pc/logo_hide.png";
import button from "@/assets/img/pc/button.png";
import underline from "@/assets/img/pc/underline.png";
import arrow from "@/assets/img/pc/arrow.png";
import close from "@/assets/img/pc/close.png";
import logo_show from "@/assets/img/pc/logo_show.png";
export default {
  data() {
    return {
      logo_hide,
      logo_show,
      button,
      underline,
      arrow,
      close,
      showNavBar: false,
      navTab: [
        { name: "星情书", code: 0 },
        { name: "2024年历", code: 1 },
      ],
      rightTab: [
        { name: "首页", code: 0 },
        { name: "产品介绍", code: 1 },
        { name: "星徒官方", code: 2 },
        { name: "加入我们", code: 3 },
      ],
      selectIndex: 0,
    };
  },
  methods: {
    tabClick(index) {
      this.selectIndex = index;
      this.showNavBar = false;
      this.$emit("tabEmit", index);
    },
    clickTab(index) {
      if (index == 0) {
        if (this.selectIndex == 1) {
          this.$parent.loversNotes = true;
          this.selectIndex = 0;
        }
        this.$parent.navTo(1);
      } else if (index == 1) {
        if (this.selectIndex == 1) {
          this.$parent.loversNotes = true;
          this.selectIndex = 0;
        }
        this.$parent.navTo(4);
      } else if (index == 2) {
        window.open("https://www.starmantech.com/", "_blank");
      } else {
        window.open("https://www.starmantech.com/career/", "_blank");
      }
      this.showNavBar = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 30px 70px;
  .nav_hide {
    justify-content: space-between;
    align-items: center;
    .nav_tab {
      transition: all 0.8s;
      .nav_name {
        color: #ffffff;
        margin: 0 40px 3px 0;
      }
      .nav_underline {
        position: absolute;
      }
    }
  }
  .nav_show {
    padding: 60px 207px 40px 160px;
    width: 100%;
    justify-content: space-between;
    .right_for {
      &:last-child {
        transform: translateY(15px);
      }
      .show_name {
        font-size: 10px;
        color: #838383;
      }
      .nav_arrow {
        margin-left: 5px;
      }
    }

    .nav_right {
      text-align: right;
      .right_tabs {
        color: #838383;
        transform: translateY(23px);
        div {
          margin-bottom: 15px;
        }
      }
    }
  }
}
:deep(.el-drawer) {
  border-radius: 0 0 68px 0;
}
</style>
