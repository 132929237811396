<template>
  <div class="page3 flex_center">
    <div ref="scrollview" class="page3_content flex_center">
      <img class="page3_bg" :src="page3_bg" ondragstart="return false;" />
      <transition name="fade-up-50">
        <div class="content" v-if="$parent.index === 3">
          <p>上古结绳记事又刀刻于竹木后人文明渐进名物繁多记述的需求愈加切迫</p>
          <p>
            一万年前中国人便开始在龟甲上划刻符号了商朝出土的甲骨文已经十分的完善随着文字的发展其载体也在不断变化兽骨陶器青铜玉石竹木丝帛等直到造纸术出现植物纤维制造而成的纸张得以成为最佳的文字载体沿用至今
          </p>
          <p>
            纸张的出现催生了印刷文明至此得以系统的保留和传承而现在纸张和笔记本依然是生活工作学习中必不可少的存在
          </p>
          <br />
          <br />
          <br />
          <p>而今天人类即将凭借AR技术迎来一场信息革命</p>
          <p>
            我们打造了以纸张为载体结合现今技术引用AR增强笔记本和增强贴纸它可以承载文字图像视频音频乃至3D模型内容并结合网络多端同步协作和记录。
          </p>
          <p>
            星徒相信它将为传统的纸张这一目前已有诸多局限性的媒介得到新的促进和增强
          </p>
        </div>
      </transition>
    </div>
    <turn />
    <img
      class="next_btn head"
      :src="next_btn"
      ondragstart="return false;"
      @click="$parent.navTo(4)"
    />
  </div>
</template>

<script>
import next_btn from "@/assets/img/pc/next_btn.png";
import page3_bg from "@/assets/img/pc/page3_bg.png";
import { VueDebounce } from "@/assets/js/common.js";
import turn from "@/components/public/turn";
export default {
  components: {
    turn,
  },
  data() {
    return {
      next_btn,
      page3_bg,
      upNum: 0,
    };
  },

  mounted() {
    this.clearNum();
  },
  methods: {
    createContent() {
      this.$nextTick(() => {
        document.body.addEventListener("mousewheel", this.handleScroll, true);
        VueDebounce("handleScroll", 3000);
      });
    },
    handleScroll(e) {
      let ev = e || window.event;
      if (ev.wheelDelta) {
        if (ev.wheelDelta > 0) {
          //当鼠标滚轮向上滚动时
          this.upNum += 30;
          if (this.$refs.scrollview) {
            this.$refs.scrollview.style.transform = `translateY(${this.upNum}px)`;
          }
          if (this.upNum >= 0) {
            this.$parent.isPrevScroll = true;
          }
        }
        if (ev.wheelDelta < 0) {
          //当鼠标滚轮向下滚动时
          this.upNum -= 30;
          if (this.$refs.scrollview) {
            this.$refs.scrollview.style.transform = `translateY(${this.upNum}px)`;
          }
          if (this.upNum <= -840) {
            this.$parent.isNextScroll = true;
          }
        }
      }
    },
    clearNum() {
      this.upNum = 0;
      this.$refs.scrollview.style.transform = `translateY(0px)`;
    },
    removeScroll() {
      document.body.removeEventListener("mousewheel", this.handleScroll, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.page3 {
  width: 100%;
  height: 100%;
  background: #000000;
  .page3_content {
    width: 100%;
    height: 100%;
    .page3_bg {
      position: relative;
      top: 500px;
    }
    .content {
      width: 1415px;
      text-align: center;
      overflow: auto;
      font-size: 32px;
      color: #b8996c;
      line-height: 71px;
      letter-spacing: 4px;
      position: absolute;
      left: 600px;
      bottom: -400px;
      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }
      p {
        line-height: 50px;
        margin-bottom: 80px;
      }
    }
  }
  .next_btn {
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: 100px;
    transition: all 0.1s linear;
    &:hover {
      transform: translateY(-5px);
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .content {
    width: 1000px !important;
    font-size: 20px !important;
    left: 450px !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .content {
    width: 1000px !important;
    font-size: 20px !important;
    left: 200px !important;
  }
}
</style>
