import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueLazyload from 'vue-lazyload'
import '@/assets/css/common.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueWow from 'vue-wow'
import "animate.css";


Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueLazyload);
Vue.use(Vant);
Vue.use(VueWow)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
