<template>
  <div class="nav_bar" :class="{ show_bg: showBg }">
    <!-- 收起内容 -->
    <div class="nav_top flex space_between align_center">
      <img class="logo" :src="logo" />
      <div class="nav_tab flex color_f">
        <div
          v-for="(item, index) in navTab"
          :key="item.code"
          @click="tabClick(index)"
        >
          <div class="show_name">{{ item.name }}</div>
          <img
            class="nav_underline"
            v-show="selectIndex == index"
            :src="underline"
            :style="{ width: selectIndex == 0 ? '48px' : '70px' }"
          />
        </div>
      </div>
      <img :src="btn" @click="show = true" width="14px" />
    </div>
    <!-- 展开内容 -->
    <van-popup v-model="show" position="top">
      <div class="pop_content flex space_between align_center">
        <img class="logo" :src="logo_black" />
        <div class="nav_tab show_tab flex">
          <div
            v-for="(item, index) in navTab"
            :key="item.code"
            @click="tabClick(index)"
          >
            <div class="show_name show">
              {{ item.name }}
            </div>
            <img
              class="nav_underline"
              v-show="selectIndex == index"
              :src="underline_black"
              :style="{ width: selectIndex == 0 ? '48px' : '70px' }"
            />
          </div>
        </div>
        <div class="nav_right">
          <img :src="close" @click="show = false" width="14px" />
          <div class="right_tabs">
            <div
              v-for="(item, index) in rightTab"
              :key="item.code"
              @click="clickTab(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import logo from "@/assets/img/mobile/logo.png";
import btn from "@/assets/img/mobile/btn.png";
import close from "@/assets/img/mobile/close.png";
import logo_black from "@/assets/img/mobile/logo_black.png";
import underline_black from "@/assets/img/mobile/underline_black.png";
import underline from "@/assets/img/pc/underline.png";
export default {
  data() {
    return {
      logo,
      btn,
      close,
      logo_black,
      underline,
      underline_black,
      navTab: [
        { name: "星情书", code: 0 },
        { name: "2024年历", code: 1 },
      ],
      rightTab: [
        { name: "首页", code: 0 },
        { name: "产品介绍", code: 1 },
        { name: "星徒官方", code: 2 },
        { name: "加入我们", code: 3 },
      ],
      show: false,
      selectIndex: 0,
      showBg: true,
    };
  },
  methods: {
    tabClick(index) {
      if (index == 0) {
        this.mobileNavTo(1);
        this.showBg = true;
      } else {
        this.showBg = false;
        window.scrollTo(0, 0);
      }
      this.selectIndex = index;
      this.$emit("selectIndex", index);
      this.show = false;
    },
    mobileNavTo(page) {
      window.scrollTo(
        0,
        this.$parent.$parent.$refs[`page${page}`].$el.offsetTop
      );
    },
    clickTab(index) {
      if (index == 0) {
        if (this.selectIndex == 1) {
          this.$parent.$parent.showNote = true;
          this.selectIndex = 0;
        }
        this.mobileNavTo(1);
      } else if (index == 1) {
        if (this.selectIndex == 1) {
          this.$parent.$parent.showNote = true;
          this.selectIndex = 0;
          setTimeout(() => {
            this.mobileNavTo(4);
          });
        } else {
          this.mobileNavTo(4);
        }
      } else if (index == 2) {
        window.open("https://www.starmantech.com/", "_blank");
      } else {
        window.open("https://www.starmantech.com/career/", "_blank");
      }
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.show_bg {
  background: #000;
}
.nav_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 15px;
  .logo {
    transform: translateY(10px);
  }
  .show_tab {
    transform: translateX(30px);
  }
  .pop_content {
    // height: 100px;
    padding: 0 30px 150px 15px;
    .nav_right {
      text-align: right;
      transform: translateY(70px);
      .right_tabs {
        min-width: 80px;
        color: #838383;
        transform: translateY(23px);
        div {
          margin-bottom: 15px;
        }
      }
    }
  }
  .show_name {
    margin-bottom: 6px;
  }
  .show {
    color: #6b6b6b;
  }
  .nav_underline {
    position: absolute;
  }
}
.nav_tab {
  div:first-child {
    margin-right: 15px;
  }
}
.van-popup {
  border-radius: 0 0 50px 0;
}
</style>
