<template>
  <div class="turn flex_center">
    <img class="turn_title" :src="turn_title" ondragstart="return false;" />
    <img class="turn_center" :src="turn_center" ondragstart="return false;" />
    <img class="turn1" :src="turn1" ondragstart="return false;" />
    <img class="turn2" :src="turn2" ondragstart="return false;" />
    <img class="turn3" :src="turn3" ondragstart="return false;" />
    <img class="turn4" :src="turn4" ondragstart="return false;" />
  </div>
</template>

<script>
import turn_center from "@/assets/img/pc/turn_center.png";
import turn_title from "@/assets/img/pc/turn_title.png";
import turn1 from "@/assets/img/pc/turn1.png";
import turn2 from "@/assets/img/pc/turn2.png";
import turn3 from "@/assets/img/pc/turn3.png";
import turn4 from "@/assets/img/pc/turn4.png";
export default {
  data() {
    return {
      turn_center,
      turn_title,
      turn1,
      turn2,
      turn3,
      turn4,
    };
  },
};
</script>

<style lang="scss" scoped>
.turn {
  position: absolute;
  right: 80px;
  img {
    position: absolute;
  }

  .turn_title{
    width: 100px;
    right: 40px;
  }
  .turn_center {
    width: 200px;
  }
  .turn1 {
    width: 50px;
    animation: rotate1 6s linear infinite;
    margin-left: 3px;
  }
  @keyframes rotate1 {
    0% {
      transform: rotateZ(0deg); /*从0度开始*/ 
    }
    100% {
      transform: rotateZ(360deg); /*360度结束*/
    }
  }
  .turn2 {
    width: 400px;
    animation: rotate2 12s linear infinite;
  }
  @keyframes rotate2 {
    0% {
      transform: rotateZ(360deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
  .turn3 {
    width: 350px;
    animation: rotate3 18s linear infinite;
  }
  @keyframes rotate3 {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
  .turn4 {
    width: 400px;
    animation: rotate4 24s linear infinite;
  }
  @keyframes rotate4 {
    0% {
      transform: rotateZ(360deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .turn {
    right: 60px !important;
  }
  .turn_center {
    width: 150px !important;
  }
  .turn1 {
    width: 40px !important;
  }
  .turn2 {
    width: 300px !important;
  }
  .turn3 {
    width: 250px !important;
  }
  .turn4 {
    width: 300px !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .turn {
    right: 60px !important;
  }
  .turn_center {
    width: 100px !important;
  }
  .turn1 {
    width: 30px !important;
  }
  .turn2 {
    width: 300px !important;
  }
  .turn3 {
    width: 250px !important;
  }
  .turn4 {
    width: 300px !important;
  }
}
</style>
