<template>
  <div class="swiper2 flex_center">
    <img class="page2_bg" :src="page2_bg" ondragstart="return false;" />
    <transition name="fade-right-50">
      <img
        class="girl"
        :src="girl"
        v-if="
          $parent.$parent.index === 4 &&
          $parent.swiper != null &&
          $parent.swiper.realIndex === 2
        "
        ondragstart="return  false;"
      />
    </transition>
    <transition name="fade-left-50">
      <img
        class="girl_name"
        :src="girl_name"
        v-if="
          $parent.$parent.index === 4 &&
          $parent.swiper != null &&
          $parent.swiper.realIndex === 2
        "
        ondragstart="return false;"
      />
    </transition>
  </div>
</template>

<script>
import page2_bg from "@/assets/img/pc/page2_bg.png";
import girl from "@/assets/img/pc/girl.png";
import girl_name from "@/assets/img/pc/girl_name.png";

export default {
  data() {
    return {
      page2_bg,
      girl,
      girl_name,
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper2 {
  .page2_bg {
    position: absolute;
    z-index: 0;
  }
  .girl,
  .girl_name {
    position: relative;
    z-index: 1;
  }
  .girl {
    margin-left: 120px;
  }
  .girl_name {
    margin: 1000px 0 0 20px;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .page2_bg {
    width: 1600px;
  }
  .girl {
    width: 1400px !important;
    margin-left: 220px !important;
  }
  .girl_name {
    margin: 900px 0 0 20px !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .page2_bg {
    width: 1100px;
  }
  .girl {
    width: 1000px !important;
    margin-left: 220px !important;
  }
  .girl_name {
    margin: 700px 0 0 20px !important;
  }
}
</style>
