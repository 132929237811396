<template>
  <div class="page2">
    <div class="page2_img flex_center">
      <img class="page2_bg" :src="page2_bg" ondragstart="return false;" />
      <transition name="fade-down-50">
        <img
          class="page2_card"
          :src="page2_card"
          v-if="$parent.index === 2"
          ondragstart="return false;"
        />
      </transition>
      <turn />
    </div>
    <img
      class="head next_btn"
      :src="next_btn"
      ondragstart="return false;"
      @click="$parent.navTo(3)"
    />
  </div>
</template>

<script>
import next_btn from "@/assets/img/pc/next_btn.png";
import page2_bg from "@/assets/img/pc/page2_bg.png";
import page2_card from "@/assets/img/pc/page2_card.png";
import turn from "@/components/public/turn";
export default {
  components: {
    turn,
  },
  data() {
    return {
      next_btn,
      page2_bg,
      page2_card,
    };
  },
};
</script>

<style lang="scss" scoped>
.page2 {
  width: 100%;
  height: 100%;
  background: #000000;
  .page2_img {
    width: 100%;
    height: 100%;
    img {
      position: absolute;
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .page2_bg {
    width: 1000px;
  }
  .page2_card {
    width: 1200px;
    height: 800px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .page2_bg {
    width: 1000px;
  }
  .page2_card {
    width: 1000px;
    height: 800px;
  }
}
</style>
