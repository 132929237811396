var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper1 flex_center"},[_c('img',{staticClass:"page2_bg",attrs:{"src":_vm.page2_bg,"ondragstart":"return false;"}}),_c('transition',{attrs:{"name":"fade-right-50"}},[(
        _vm.$parent.$parent.index === 4 &&
        _vm.$parent.swiper != null &&
        _vm.$parent.swiper.realIndex === 0
      )?_c('img',{staticClass:"page4_book",attrs:{"src":_vm.page4_book,"ondragstart":"return false;"}}):_vm._e()]),_c('transition',{attrs:{"name":"fade-down-50"}},[(
        _vm.$parent.$parent.index === 4 &&
        _vm.$parent.swiper != null &&
        _vm.$parent.swiper.realIndex === 0
      )?_c('div',{staticClass:"content"},[_c('div',{staticClass:"content_title"},[_c('div',[_vm._v("Couple Notebook")]),_c('div',[_vm._v("一捧日月-恋之记")])]),_c('div',{staticClass:"content_content"},[_c('div',[_vm._v(" 融合了现实和虚拟的情侣笔记本，共同记录着爱情的美好瞬间——通过星情书APP扫描，出现你与TA的记录 ")]),_c('div',[_vm._v("可以记录自己悄悄话，也可以同步俩人的在一起的甜蜜时光")]),_c('div',[_vm._v("时刻记录，同步内容")])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }