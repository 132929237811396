var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper2 flex_center"},[_c('img',{staticClass:"page2_bg",attrs:{"src":_vm.page2_bg,"ondragstart":"return false;"}}),_c('transition',{attrs:{"name":"fade-right-50"}},[(
        _vm.$parent.$parent.index === 4 &&
        _vm.$parent.swiper != null &&
        _vm.$parent.swiper.realIndex === 2
      )?_c('img',{staticClass:"girl",attrs:{"src":_vm.girl,"ondragstart":"return  false;"}}):_vm._e()]),_c('transition',{attrs:{"name":"fade-left-50"}},[(
        _vm.$parent.$parent.index === 4 &&
        _vm.$parent.swiper != null &&
        _vm.$parent.swiper.realIndex === 2
      )?_c('img',{staticClass:"girl_name",attrs:{"src":_vm.girl_name,"ondragstart":"return false;"}}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }