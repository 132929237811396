<template>
  <div class="page1">
    <div class="page1_img flex_center">
      <img class="page1_bg" :src="page1_bg" ondragstart="return false;" />
      <transition name="fade-down-50">
        <img
          class="page1_title"
          :src="page1_title"
          v-if="$parent.index === 1"
          ondragstart="return false;"
        />
      </transition>
      <turn />
    </div>
    <transition name="fade-right-50">
      <div class="page1_left" v-if="$parent.index === 1">
        <a href="https://item.taobao.com/item.htm?id=723270511214&spm=a1z10.1-c-s.w4004-24950335639.2.32d3641bnICEWx" target="_blank">
          <img class="tb head" :src="tb" ondragstart="return false;" />
        </a>
        <br />
        <a href="https://apps.apple.com/app/%E6%98%9F%E7%AC%94%E8%AE%B0/id6446580372" target="_blank">
          <img
            class="apple head"
            :src="apple"
            ondragstart="return false;"
          />
        </a>
        <br />
        <a href="/starnote_v1.0.0.apk" target="_blank">
          <img class="android head" :src="android" ondragstart="return false;" />
        </a>
      </div>
    </transition>
    <div class="next-icon">
      <i class="el-icon-arrow-down" @click="$parent.navTo(2)" />
    </div>
  </div>
</template>

<script>
import page1_bg from "@/assets/img/pc/page1_bg.png";
import page1_title from "@/assets/img/pc/page1_title.png";
import tb from "@/assets/img/pc/tb.png";
import apple from "@/assets/img/pc/apple.png";
import android from "@/assets/img/pc/android.png";
import turn from "@/components/public/turn";
export default {
  components: {
    turn,
  },
  data() {
    return {
      page1_bg,
      page1_title,
      tb,
      apple,
      android,
    };
  },
};
</script>

<style lang="scss" scoped>
.page1 {
  width: 100%;
  height: 100%;
  background: #000000;
  .page1_img {
    width: 100%;
    height: 100%;
    img {
      position: absolute;
    }
  }
  .page1_left {
    position: absolute;
    bottom: 40px;
    left: 130px;
    img {
      margin-bottom: 32px;
    }
  }
  .next-icon {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    color: #ffffff;
    font-size: 23px;
    cursor: pointer;
    transition: all linear 0.1s;
    z-index: 2;
    &:hover {
      transform: translateY(-5px);
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .page1_bg {
    width: 1500px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .page1_bg {
    width: 1200px;
  }
}
</style>
