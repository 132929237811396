<template>
  <div class="page6 flex align_center">
    <transition name="fade-right-50">
      <div class="page6_title" v-if="$parent.index === 6">
        <div class="title">
          <div>RECORD THE STARSAR</div>
          <div>魔法贴纸星贴贴</div>
        </div>
        <div class="content">
          <div>
            一种结合现实和虚拟的新媒介，AR魔法贴纸，你可以将它贴在任何地方——通过恒记APP扫描，出现你自定义的AR内容。
          </div>
          <div>
            局限你的只有想象力，你可以放置文字寄语、图片照片、视频录像…甚至3D模型，到你想要的一切地方。
          </div>
          <div>夹在书里？贴在墙上？粘在脑门上？</div>
        </div>
      </div>
    </transition>
    <transition name="fade-left-50">
      <img
        class="page6_img"
        :src="page6_img"
        v-if="$parent.index === 6"
        ondragstart="return false;"
      />
    </transition>
    <!-- <img
      class="head next_btn"
      :src="next_btn"
      ondragstart="return false;"
      @click="$parent.navTo(6)"
    /> -->
  </div>
</template>

<script>
import next_btn from "@/assets/img/pc/next_btn.png";
import page6_img from "@/assets/img/pc/page6_img.png";
export default {
  data() {
    return {
      next_btn,
      page6_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.page6 {
  width: 100%;
  height: 100%;
  background: #000000;
  .page6_title {
    color: #b8996c;
    margin-left: 180px;
    .title {
      div:first-child {
        font-size: 28px;
      }
      div:last-child {
        font-size: 88px;
      }
    }
    .content {
      width: 550px;
      margin-top: 62px;
      line-height: 71px;
      letter-spacing: 4px;
    }
  }
  img {
    position: absolute;
    right: 0;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .page6_img {
    width: 1150px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .page6_title{
    margin-left: 80px !important;
  }
  .page6_img {
    width: 1050px;
  }
}
</style>
