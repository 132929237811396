<template>
  <div>
    <u-animate-container>
      <navBar ref="navbar" v-show="showNavBar" @selectIndex="selectIndex" />
      <div
        class="note"
        v-show="showNote"
        @touchstart="touchstart"
        @touchmove="touchmove"
      >
        <page1 ref="page1" />
        <page2 ref="page2" />
        <page3 ref="page3" />
        <page4 ref="page4" />
        <page5 ref="page5" />
        <page6 ref="page6" />
      </div>
      <div class="logo flex_center" v-show="!showNote">
        <img :src="logo" />
      </div>
      <foot />
    </u-animate-container>
  </div>
</template>

<script>
import navBar from "@/components/mobile/navBar";
import page1 from "@/components/mobile/page1";
import page2 from "@/components/mobile/page2";
import page3 from "@/components/mobile/page3";
import page4 from "@/components/mobile/page4";
import page5 from "@/components/mobile/page5";
import page6 from "@/components/mobile/page6";
import foot from "@/components/mobile/foot";
import logo from "@/assets/img/mobile/2024_logo.png";
export default {
  components: {
    navBar,
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    foot,
  },
  data() {
    return {
      logo,
      showNote: true,
      showNavBar: true,
      startX: 0,
      startY: 0,
      moveX: 0,
      moveY: 0,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", () => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    selectIndex(index) {
      if (index == 0) {
        this.showNote = true;
      } else if (index == 1) {
        this.showNote = false;
      }
    },
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      if (this.startY - this.moveY <= 0) {
        console.log(111);
        this.showNavBar = true;
        return false;
      } else {
        this.showNavBar = false;
        return false;
      }
      if (this.startX - this.moveX <= 0) {
        console.log(132);
      }
      // if (this.startX - this.moveX <= 0) {
      //   this.showNavBar = true;
      // } else {
      //   this.showNavBar = true;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 100vh;
  background: #ad2020;
}
.v-touch {
  touch-action: pan-y !important;
}
</style>
