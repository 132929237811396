<template>
  <div class="page5 flex just_center">
    <u-animate
      name="animate__fadeIn"
      delay="0s"
      duration="1s"
      :iteration="1"
      :offset="0"
      animateClass="animated"
      :begin="false"
    >
      <div class="title">
        <div class="title_top">
          <div>RECORD THE STARSAR</div>
          <div>魔法贴纸星贴贴</div>
        </div>
        <div class="title_content">
          <p>
            一种结合现实和虚拟的新媒介，AR魔法贴纸，你可以将它贴在任何地方——通过恒记APP扫描，出现你自定义的AR内容。
          </p>
          <p>
            局限你的只有想象力，你可以放置文字寄语、图片照片、视频录像…甚至3D模型，到你想要的一切地方。
          </p>
          <p>夹在书里？贴在墙上？粘在脑门上？</p>
        </div>
      </div>
    </u-animate>
    <img class="page5_img" :src="page5_img" width="100%" />
  </div>
</template>

<script>
import page5_img from "@/assets/img/mobile/page5_img.png";
export default {
  data() {
    return {
      page5_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.page5 {
  height: 100vh;
  background: #000;
  position: relative;
  text-align: center;
  .title {
    position: relative;
    z-index: 1;
    color: #b8996c;
    padding-top: calc(100% - 300px);
    .title_top {
      div:first-child {
        font-size: 0.3rem;
      }
      div:last-child {
        font-size: 2rem;
        font-family: Taipei Sans TC Beta-Bold, Taipei Sans TC Beta;
        font-weight: bold;
      }
    }
    .title_content {
      width: calc(100% - 100px);
      margin: 0 auto;
      font-size: 12px;
      letter-spacing: 3px;
      P {
        margin-bottom: 30px;
      }
    }
  }
  .page5_img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
}
@media screen and (min-width: 750px) and (max-width: 960px) {
  .title {
    padding-top: 0 !important;
  }
}

</style>
