<template>
  <div class="page3">
    <div class="page3_content">
      <u-animate
        name="animate__fadeInDown"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false"
      >
        <img class="page3_img" :src="page3_img" width="30%" />
      </u-animate>
      <u-animate
        name="animate__fadeIn"
        delay="0s"
        duration="1s"
        :iteration="1"
        :offset="0"
        animateClass="animated"
        :begin="false"
      >
        <div class="content">
          <p>上古结绳记事又刀刻于竹木</p>
          <p>后人文明渐进名物繁多</p>
          <p>记述的需求愈加切迫</p>
          <p>一万年前中国人便开始在龟甲上划刻符号了</p>
          <p>商朝出土的甲骨文已经十分的完善</p>
          <p>随着文字的发展其载体也在不断变化</p>
          <p>兽骨陶器青铜玉石竹木丝帛等</p>
          <p>直到造纸术出现</p>
          <p>植物纤维制造而成的纸张</p>
          <p>得以成为最佳的文字载体</p>
          <p>沿用至今</p>
          <p>纸张的出现催生了印刷</p>
          <p>文明至此得以系统的保留和传承</p>
          <p>而现在纸张和笔记本依然是生活工作学习中</p>
          <p>必不可少的存在</p>
          <p>我们打造了</p>
          <p>以纸张为载体结合现今技术</p>
          <p>引用AR增强笔记本和增强贴纸</p>
          <p>以它可以承载</p>
          <p>文字 图像 视频 音频</p>
          <p>乃至3D模型内容</p>
          <p>并结合网络多端同步</p>
          <p>协作和记录</p>
          <p>星徒相信</p>
          <p>它将为传统的纸张这一目前</p>
          <p>已有诸多局限性的媒介</p>
          <p>得到新的促进和增强</p>
        </div>
      </u-animate>
    </div>
  </div>
</template>

<script>
import page3_img from "@/assets/img/mobile/page3_img.png";
export default {
  data() {
    return {
      page3_img,
    };
  },
};
</script>

<style lang="scss" scoped>
.page3 {
  height: 130vh;
  background: #000;
  position: relative;
  text-align: center;
  .page3_content {
    padding-top: 100px;
    .content {
      width: calc(100% - 80px);
      color: #b8996c;
      margin: 0 auto;
      letter-spacing: 2px;
      font-size: 12px;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 359px) {
  .page3 {
    height: 180vh !important;
  }
  .content {
    font-size: 8px;
    line-height: 15px !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 374px) {
  .page3 {
    height: 160vh !important;
  }
  .content {
    font-size: 10px;
    line-height: 22px !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 385px) {
  .page3 {
    height: 180vh !important;
  }
  .content {
    font-size: 10px;
    line-height: 20px !important;
  }
}
@media screen and (min-width: 386px) and (max-width: 392px) {
  .page3 {
    height: 120vh !important;
  }
  .content {
    font-size: 12px;
  }
}
@media screen and (min-width: 393px) and (max-width: 400px) {
  .page3 {
    height: 160vh !important;
  }
}
@media screen and (min-width: 401px) and (max-width: 414px) {
  .page3 {
    height: 140vh !important;
  }
  .content {
    font-size: 12px;
    line-height: 25px !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 540px) {
  .page3 {
    height: 180vh !important;
  }
  .content {
    width: calc(100% - 60px) !important;
  }
}
@media screen and (min-width: 750px) and (max-width: 799px) {
}
</style>
