<template>
  <div class="page6 flex_center">
    <div class="page6_title">
      <div class="title_animation"></div>
      <div class="title_bg_animation"></div>
      <div class="font">记录星辰-星贴贴</div>
    </div>
    <!-- 模型 -->
    <div class="model">
      <div id="unity-container">
        <canvas id="unity-canvas"></canvas>
        <div id="unity-loading-bar">
          <div id="unity-logo"></div>
          <div id="unity-progress-bar-empty">
            <div id="unity-progress-bar-full"></div>
          </div>
        </div>
        <div id="unity-warning"></div>
        <div id="unity-footer">
          <div id="unity-webgl-logo" style="opacity: 0"></div>
          <div id="unity-fullscreen-button" style="opacity: 0"></div>
          <div id="unity-build-title"></div>
        </div>
      </div>
      <img class="arrow" :src="arrow" />
    </div>
    <!-- <div class="shop">
      <div class="flex">
        <img class="qr_code" :src="qr_code" />
        <div class="link">
          <img class="page6_tb" :src="page6_tb" />
          <img class="page6_apple" :src="page6_apple" />
          <img class="page6_android" :src="page6_android" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import title from "@/assets/img/mobile/title.png";
import title_bg from "@/assets/img/mobile/title_bg.png";
import arrow from "@/assets/img/mobile/arrow.png";
import qr_code from "@/assets/img/mobile/qr_code.png";
import page6_tb from "@/assets/img/mobile/page6_tb.png";
import page6_apple from "@/assets/img/mobile/page6_apple.png";
import page6_android from "@/assets/img/mobile/page6_android.png";
export default {
  data() {
    return {
      title,
      title_bg,
      arrow,
      qr_code,
      page6_tb,
      page6_apple,
      page6_android,
      container: Object,
      canvas: Object,
      loadingBar: Object,
      progressBarFull: Object,
      fullscreenButton: Object,
      warningBanner: Object,
    };
  },
  mounted() {
    this.unityShowBanner();
  },
  methods: {
    unityShowBanner(msg, type) {
      this.container = document.querySelector("#unity-container");
      this.canvas = document.querySelector("#unity-canvas");
      this.loadingBar = document.querySelector("#unity-loading-bar");
      this.progressBarFull = document.querySelector("#unity-progress-bar-full");
      this.fullscreenButton = document.querySelector(
        "#unity-fullscreen-button"
      );
      this.warningBanner = document.querySelector("#unity-warning");
      var div = document.createElement("div");
      div.innerHTML = msg;
      this.warningBanner.appendChild(div);
      if (type == "error") div.style = "background: black; padding: 10px;";
      else {
        if (type == "warning") div.style = "background: black; padding: 10px;";
        setTimeout(function () {
          if (this.warningBanner) {
            this.warningBanner.removeChild(div);
          }
        }, 5000);
      }
      var buildUrl = "Build";
      var loaderUrl = buildUrl + "/Web.loader.js";
      var config = {
        dataUrl: buildUrl + "/Web.data.unityweb",
        frameworkUrl: buildUrl + "/Web.framework.js.unityweb",
        codeUrl: buildUrl + "/Web.wasm.unityweb",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "DefaultCompany",
        productName: "LotteryDemo",
        productVersion: "0.1.0",
      };
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        this.canvas.style.background = "#000";
      }
      this.canvas.style.width = "300px";
      this.canvas.style.height = "300px";
      this.loadingBar.style.display = "block";
      var script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        createUnityInstance(this.canvas, config, (progress) => {
          this.progressBarFull.style.width = 100 * progress + "%";
        })
          .then((unityInstance) => {
            this.loadingBar.style.display = "none";
            setTimeout(function () {
              document.getElementById("unity-canvas").style.opacity = 1;
            }, 3000);
            this.fullscreenButton.onclick = () => {
              unityInstance.SetFullscreen(1);
            };
          })
          .catch((message) => {
            alert(message);
          });
      };
      document.body.appendChild(script);
    },
  },
};
</script>

<style lang="scss" scoped>
#unity-warning {
  display: none !important;
}
.page6 {
  height: 100vh;
  background: #000;
  position: relative;
  text-align: center;
  overflow: hidden;
  .page6_title {
    width: 100%;
    margin: 0 auto;
    .title_animation {
      width: 1939px;
      height: 121px;
      background: url("@/assets/img/mobile/title.png");
      background-repeat: repeat-x;
      background-size: 100% 150px;
      overflow: hidden;
      animation: titleMove 8s linear infinite normal;
      top: 100px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 999;
    }
    @keyframes titleMove {
      0% {
        background-position: 0px;
      }
      100% {
        background-position: -1939px;
      }
    }
    .title_bg_animation {
      width: 2259px;
      height: 180px;
      background: url("@/assets/img/mobile/title_bg.png");
      background-repeat: repeat-x;
      background-size: 100% 150px;
      animation: titleBgMove 8s infinite linear;
      top: 120px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 999;
    }
    @keyframes titleBgMove {
      0% {
        background-position: -60px;
      }
      100% {
        background-position: -2319px;
      }
    }
    .font {
      height: 1px;
      position: absolute;
      top: 220px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      letter-spacing: 1.5rem;
      color: #806b4b;
    }
  }
  .model {
    position: relative;
    z-index: 0;
    left: -50px;
    bottom: -50px;
    .arrow {
      width: 30%;
      position: absolute;
      left: 30px;
      bottom: 170px;
    }
  }
  .shop {
    position: absolute;
    bottom: 20px;
    left: calc(100% - 340px);
    .page6_tb,
    .page6_apple,
    .page6_android {
      width: 138px;
      height: 40px;
    }
    .qr_code {
      margin-right: 10px;
    }
    .link {
      width: 0;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 359px) {
  .qr_code {
    width: 100px !important;
    height: 100px !important;
  }
  .page6_tb,
  .page6_apple,
  .page6_android {
    width: 100px !important;
    height: 30px !important;
  }
  #unity-canvas {
    width: 200px !important;
    height: 200px !important;
  }
  .title_animation,
  .title_bg_animation {
    height: 100px !important;
  }
  .font {
    top: 210px !important;
    letter-spacing: 0.8rem !important;
  }
  .arrow {
    bottom: 120px !important;
  }
  .shop {
    bottom: 24px !important;
    left: calc(100% - 265px) !important;
  }
}
@media screen and (min-width: 360px) and (max-width: 374px) {
  .shop {
    left: calc(100% - 320px) !important;
  }
  .model {
    left: -20px !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 414px) {
  .shop {
    bottom: 10px !important;
  }
}
@media screen and (min-width: 401px) and (max-width: 414px) {
  .shop {
    bottom: 30px !important;
  }
}
@media screen and (min-width: 750px) and (max-width: 960px) {
  #unity-canvas {
    width: 500px !important;
    height: 500px !important;
  }
  .arrow{
    bottom: 280px !important;
  }
  .model{
    left: -125px !important;
  }
  .shop {
    left: 260px !important;
  }
}
</style>
