<template>
  <div class="swiper1 flex_center">
    <img class="page2_bg" :src="page2_bg" ondragstart="return false;" />
    <transition name="fade-right-50">
      <img
        class="page4_book"
        :src="page4_book"
        v-if="
          $parent.$parent.index === 4 &&
          $parent.swiper != null &&
          $parent.swiper.realIndex === 0
        "
        ondragstart="return false;"
      />
    </transition>

    <transition name="fade-down-50">
      <div
        class="content"
        v-if="
          $parent.$parent.index === 4 &&
          $parent.swiper != null &&
          $parent.swiper.realIndex === 0
        "
      >
        <div class="content_title">
          <div>Couple Notebook</div>
          <div>一捧日月-恋之记</div>
        </div>
        <div class="content_content">
          <div>
            融合了现实和虚拟的情侣笔记本，共同记录着爱情的美好瞬间——通过星情书APP扫描，出现你与TA的记录
          </div>
          <div>可以记录自己悄悄话，也可以同步俩人的在一起的甜蜜时光</div>
          <div>时刻记录，同步内容</div>
        </div>
      </div>
    </transition>
    <!-- <transition name="fade-left-50">
      <div
        class="content_imgs"
        v-if="
          $parent.$parent.index === 4 &&
          $parent.swiper != null &&
          $parent.swiper.realIndex === 0
        "
      >
        <img
          class="qr_code head"
          :src="qr_code"
          ondragstart="return false;"
        /><br />
        <img
          class="tb head"
          :src="tb"
          ondragstart="return false;"
          @click="clickTb"
        /><br />
        <img
          class="apple head"
          :src="apple"
          ondragstart="return false;"
          @click="clickApple"
        /><br />
        <img
          class="android head"
          :src="android"
          ondragstart="return false;"
          @click="clickAndroid"
        />
      </div>
    </transition> -->
  </div>
</template>

<script>
import page4_book from "@/assets/img/pc/page4_book.png";
import page2_bg from "@/assets/img/pc/page2_bg.png";
import qr_code from "@/assets/img/pc/qr_code.png";
import tb from "@/assets/img/pc/tb.png";
import apple from "@/assets/img/pc/apple.png";
import android from "@/assets/img/pc/android.png";
export default {
  data() {
    return {
      page4_book,
      page2_bg,
      qr_code,
      tb,
      apple,
      android,
    };
  },
  methods: {
    clickTb() {
      window.open("https://www.baidu.com/", "_blank");
    },
    clickApple() {
      window.open("https://www.baidu.com/", "_blank");
    },
    clickAndroid() {
      window.open("https://www.baidu.com/", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper1 {
  height: 100%;
  .page2_bg {
    position: absolute;
    z-index: 0;
  }
  .page4_book,
  .content,
  .content_imgs {
    position: relative;
    z-index: 1;
  }
  .page4_book {
    left: -150px;
  }
  .content {
    width: 682px;
    color: #b8996c;
    text-align: right;
    .content_title {
      margin-bottom: 82px;
      div:first-child {
        font-size: 24px;
      }
      div:last-child {
        font-size: 72px;
      }
    }
    .content_content {
      font-size: 32px;
      div {
        margin-bottom: 100px;
      }
    }
  }
  .content_imgs {
    margin: 180px 0 0 200px;
    .qr_code {
      margin-bottom: 17px;
    }
    .tb,
    .apple {
      margin-bottom: 32px;
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .page4_book {
    width: 700px;
  }
  .content {
    width: 537px !important;
    .content_content {
      font-size: 26px !important;
    }
  }
  .content_imgs {
    margin: 80px 0 0 50px !important;
    img {
      width: 180px !important;
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .page2_bg {
    width: 1100px;
  }
  .page4_book {
    width: 500px;
  }
  .content_title {
    div:first-child {
      font-size: 18px !important;
    }
    div:last-child {
      font-size: 36px !important;
    }
  }
  .content {
    width: 386px !important;
    .content_content {
      font-size: 20px !important;
    }
  }
  .content_imgs {
    margin: 80px 0 0 50px !important;
    img {
      width: 150px !important;
    }
  }
}
</style>
