<template>
  <div class="page4">
    <div class="swiper-container swiper-container-team">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide">
          <swiper1 />
        </div>
        <div class="swiper-slide">
          <swiper2 />
        </div>
        <div class="swiper-slide">
          <swiper3 />
        </div>
      </div>
    </div>
    <turn class="turn" />
    <img
      class="next_btn head"
      :src="next_btn"
      ondragstart="return false;"
      @click="$parent.navTo(5)"
    />
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import next_btn from "@/assets/img/pc/next_btn.png";
import qr_code from "@/assets/img/pc/qr_code.png";
import turn from "@/components/public/turn";
import swiper1 from "@/components/pc/swiper1";
import swiper2 from "@/components/pc/swiper2";
import swiper3 from "@/components/pc/swiper3";
export default {
  name: "HeroPage",
  components: {
    turn,
    swiper1,
    swiper2,
    swiper3
  },
  data() {
    return {
      next_btn,
      qr_code,
      swiper: null,
    };
  },
  mounted() {
    this.myswiper();
  },
  methods: {
    myswiper: function () {
      let that = this;
      this.swiper = new Swiper(".swiper-container-team", {
        mousewheel: true,
        on: {
          // 往后滑动
          slideNextTransitionEnd: function () {
            // 有n个等于n-1
            if (this.activeIndex === 2) {
              that.$parent.isNextScroll = true;
            }
            that.$parent.isPrevScroll = false;
          },
          // 往前滑动
          slidePrevTransitionEnd: function () {
            if (this.activeIndex === 0) {
              that.$parent.isPrevScroll = true;
            }
            that.$parent.isNextScroll = false;
          },
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.page4 {
  background: #000000;
  .turn {
    top: 600px;
  }
}
.swiper-container {
  height: 100vh;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1920px) {
  .turn {
    top: 500px !important;
  }
}
</style>
