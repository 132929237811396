<template>
  <div class="foot">
    <img class="foot_logo" :src="foot_logo" />
    <div class="content color_f">
      <div class="font">© 2020-2023 星徒（上海）科技发展有限公司</div>
      <div class="flex_center font min_font">
        <div class="ml20">
          <a href="https://beian.miit.gov.cn/">沪ICP备2021025443号-1</a>
        </div>
        <div class="flex_center">
          <img :src="icon" />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007132"
            >沪公网安备 31010102007132号</a
          ><br />
        </div>
      </div>
      <div class="font">
        商务合作: <a href="mailto:bd@starman.ltd">bd@starman.ltd</a> | 招聘相关:
        <a href="mailto:hr@starman.ltd">hr@starman.ltd</a><br />
      </div>
    </div>
  </div>
</template>

<script>
import foot_logo from "@/assets/img/mobile/foot_logo.png";
import icon from "@/assets/img/mobile/icon.png";
export default {
  data() {
    return {
      foot_logo,
      icon,
    };
  },
};
</script>

<style lang="scss" scoped>
.foot {
  min-height: calc(100% - 48px);
  background: #000;
  text-align: center;
  .foot_logo {
    margin: 40px 0;
  }
  .font {
    font-size: 0.7rem;
    margin-bottom: 5px;
    .ml20 {
      margin-right: 20px;
    }
  }
  .content {
    padding-bottom: 40px;
    a {
      color: #fff;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 359px) {
  .min_font {
    display: block;
  }
}
</style>
