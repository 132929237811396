<template>
  <div>
    <pcIndex v-if="!isMobile" />
    <mobileIndex v-else />
  </div>
</template>

<script>
import pcIndex from "@/components/pc/index.vue";
import mobileIndex from "@/components/mobile/index.vue";
export default {
  components: {
    pcIndex,
    mobileIndex,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  beforeMount() {
    window.addEventListener("resize", this.handleLayouts);
    this.handleLayouts();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleLayouts);
  },
  methods: {
    handleLayouts() {
      // 移动端的自适应切换
      const isMobile = document.body.getBoundingClientRect().width - 1 < 1280;
      if (this.isMobile !== isMobile) {
        this.isMobile = isMobile;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
