<template>
  <div class="page1 flex_center">
    <img class="page1_bg" :src="page1_bg" width="100%" />
    <u-animate
      name="animate__fadeIn"
      delay="0s"
      duration="1s"
      :iteration="1"
      :offset="0"
      animateClass="animated"
      :begin="false"
    >
      <img class="page1_title" :src="page1_title" />
    </u-animate>
    <div class="page1_foot">
      <a href="https://item.taobao.com/item.htm?id=723270511214&spm=a1z10.1-c-s.w4004-24950335639.2.32d3641bnICEWx" target="_blank">
        <img class="page1_tb" :src="page1_tb" />
      </a>
      <br />
      <a href="https://apps.apple.com/app/%E6%98%9F%E7%AC%94%E8%AE%B0/id6446580372" target="_blank">
        <img class="page1_apple" :src="page1_apple" />
      </a>
      <br />
      <a href="/starnote_v1.0.0.apk" target="_blank">
        <img class="page1_android" :src="page1_android" />
      </a>
    </div>
  </div>
</template>

<script>
import page1_android from "@/assets/img/mobile/page1_android.png";
import page1_apple from "@/assets/img/mobile/page1_apple.png";
import page1_bg from "@/assets/img/mobile/page1_bg.png";
import page1_tb from "@/assets/img/mobile/page1_tb.png";
import page1_title from "@/assets/img/mobile/page1_title.png";
export default {
  data() {
    return {
      page1_android,
      page1_apple,
      page1_bg,
      page1_tb,
      page1_title,
    };
  },
};
</script>

<style lang="scss" scoped>
.page1 {
  height: 100vh;
  background: #000;
  text-align: center;
  .page1_bg {
    position: absolute;
  }
  .page1_title {
    width: calc(100% - 40px);
    padding-bottom: 180px;
  }
  .page1_foot {
    position: absolute;
    bottom: 100px;
    img {
      width: calc(100% - 120px);
      margin-bottom: 15px;
    }
  }
}
</style>
